module.exports = {
  sentryDSN: 'https://6e13eaaa5e984ec4a8d450bb636e45d3@sentry.io/1293269',
  sentryReleasePrefix: 'defaultp@',
  apiEndpoint: 'https://api.production.shootsta.com/',
  serviceEndpoint: 'https://service-defaultp.production.shootsta.com/',
  subscriptionsEndpoint: 'wss://service-defaultp.production.shootsta.com/subscriptions',
  ssoApiEndpoint: 'https://sso.shootsta.com/saml',
  shootstaDomainSegments: 'develop,staging,app',
  skipNotifications: 'true',
  defaultReturnToSuccessEndpoint: 'https://app.shootsta.com',
  basicWorkspaceUrl: 'https://app.shootsta.com',
  socialGoogleClientId: '',
  socialLinkedInClientId: '',
  socialAppleClientId: '',
  recaptchaSiteKey: '6LeMD3gmAAAAAH3jvi9s1euAyqe7k8-UjL5uTa6V'
};
